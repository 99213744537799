/* eslint-disable prefer-arrow-callback */
/* eslint-disable operator-linebreak, prefer-template */
import Bowser from 'bowser/es5';
import settings from 'app/modules/shell/settings';
import unkwnown from 'configs/unknown';

export const className = 'compatibility-modal';
export const closeButtonClassName = 'close-button';

export function isMobileBrowserWithoutVersion(bowser) {
  return bowser.getBrowserName() !== undefined
    && bowser.getBrowserVersion() === undefined
    && bowser.getPlatformType() === 'mobile';
}
export function isWeChatBrowser(bowser) {
  return bowser.getBrowserName(true) === 'wechat' || bowser.getBrowserName(true).includes('cfnetwork');
}

export function isCompatible(userAgent) {
  const bowser = Bowser.getParser(userAgent);
  const skipList = [
    'android',
    'ios',
    'wechat mobile app',
    'cfnetwork',
  ];

  if (isMobileBrowserWithoutVersion(bowser)) {
    return skipList.include(bowser.getOSName(true));
  }

  if (isWeChatBrowser(bowser)) {
    return true;
  }

  const list = {
    'Chrome': '>=93',
    'Microsoft Edge': '>=92',
    'Samsung Internet for Android': '>=3.3',
    'Firefox': '>=97',
    'Opera': '>=95',
    'Safari': '>=13',
    'Googlebot': '>=2.1',
    'Mozilla': '>=5.0',
    'Android Browser': '>=4.0',
    'Amazon Silk': '>=47',
    'WeChat': '>=2',
  };
  return bowser.satisfies(list);
}

export function isGoogleSearchApp() {
  return settings.platform.browser === unkwnown.unknown && settings.platform.version >= 220;
}

export function showModal() {
  // TODO: translate to various languages
  const texts = {
    closeButtonLabel: 'Close modal',
    modalLabel: 'Browser compatibility message',
    modalContent:
      'Your browser is out of date and has known security issues.<br><br>' +
      'It also may not display all features of this website or other websites.<br><br>' +
      'Please upgrade your browser to access all of the features of this website.<br><br>' +
      'Latest version for Google Chrome, Mozilla Firefox or Microsoft Edge' +
      'is recommended for optimal functionality.<br>'
    ,
  };
  const html = '' +
  '<div aria-label="' + texts.modalLabel + '" class="' + className + ' se-reset-css" style="' +
      'position: fixed;' +
      'top: 0;' +
      'right: 0;' +
      'bottom: 0;' +
      'left: 0;' +
      'display: flex;' +
      'justify-content: center;' +
      'align-items: center;' +
      'width: 100%;' +
      'background: rgba(4, 4, 4, 0.6);' +
      'z-index: 70;' +
  '">' +
    '<div style="' +
        'position: relative;' +
        'width: 70vw;' +
        'max-height: 500px;' +
        'max-width: 500px;' +
        'margin: 0;' +
        'background: #fff;' +
        'overflow: auto;' +
    '">' +
      '<div style="padding: 35px;">' +
        '<div class="se-rich-text">' + texts.modalContent + '</div>' +
      '<button' +
        ' aria-label="' + texts.closeButtonLabel + '"' +
        ' class="' + closeButtonClassName + '" style="' +
          'position: absolute;' +
          'top: 15px;' +
          'right: 15px;' +
          'padding: 0;' +
          'color: #333;' +
          'border: none;' +
          'cursor: pointer;' +
          'opacity: 0.5;' +
          'background: transparent;' +
      '">' +
        '<span style="font-weight: bold; color: black; font-size: 20px">X</span>' +
      '</button>' +
    '</div>' +
  '</div>';

  document.body.innerHTML += html;

  const compatibilityModal = document.querySelector('.' + className);
  // eslint-disable-next-line func-names
  compatibilityModal.addEventListener('click', function () {
    compatibilityModal.parentElement.removeChild(compatibilityModal);
  });
}

if (!isCompatible(navigator.userAgent)) {
  if (!isGoogleSearchApp()) {
    showModal();
  }
}
